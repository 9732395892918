import fp from 'lodash/fp'
import propTypes from 'prop-types'
import React, { Component } from 'react'

import './dropdown.css';


class Dropdown extends Component {

	static displayName = 'SearchPage'

    static propTypes = {
		className: propTypes.string,
		label: propTypes.string,
		items: propTypes.arrayOf(
			propTypes.shape({
				name: propTypes.string,
				uid: propTypes.string,
			})
		),
		itemClassName: propTypes.string,
		itemClicked: propTypes.func.isRequired
	}

	constructor(props) {

		super(props)

		this.state = {
			open: false
		}

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	setWrapperRef(node) {

		this.wrapperRef = node;
	}

	handleClickOutside(event) {

		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {

			this.setState({ open: false })
		}
	}

	componentDidMount() {

		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {

		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	openDropDown = () => {

		this.setState({ open: !this.state.open })
	}

	render() {

		const { props, state } = this

		const Items = fp.map(item => {

			return <div key={item.uid} {...{
					className: `dropdown-item ${props.itemClassName}`,
                    children: item.name,
                    id: item.uid,
					onClick: props.itemClicked
				}} />
		}, props.items)

		return (

            <div className='dropdown' ref={this.setWrapperRef}>

                <div {...{
					className: props.className,
					children: props.label,
					onClick: this.openDropDown
				}} />

                {state.open && (

                    <div className='dropdown-content'>
                        {Items}
                    </div>

                )}

            </div>
		)
	}
}

export default Dropdown