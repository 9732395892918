import propTypes from 'prop-types'
import React, { Component } from 'react'
import VigneronPropTypes from '../prop-types/vigneron-prop-types'

import './vigneron-renderer.css';

class VigneronRenderer extends Component {

    static displayName = 'VigneronRenderer'

    static propTypes = {
        goToPage: propTypes.func,
        vigneron: propTypes.shape(VigneronPropTypes)
    }

    onVigneronSelected = () => {

        if (this.props.goToPage) {
            this.props.goToPage({
                data: this.props.vigneron,
                page: 'vigneron'
            })
        }
    }

    render() {

        return (

            <h1 className='vigneron-button' onClick={this.onVigneronSelected}>
                {this.props.vigneron.name}
            </h1>
        )
    }
}

export default VigneronRenderer