import fp from 'lodash/fp'
import propTypes from 'prop-types'
import React, { Component } from 'react'
import WinePropTypes from '../prop-types/wine-prop-types'

import './wine-renderer.css';

class WineRenderer extends Component {

    static displayName = 'WineRenderer'

    static propTypes = {
        onWineSelected: propTypes.func,
        wine: propTypes.shape(WinePropTypes)
    }

    onClick = () => {

        if (!fp.isUndefined(this.props.onWineSelected)) {

            const vigneronUID = this.props.wine.uid.slice(0, -3)

            this.props.onWineSelected(vigneronUID)
        }
    }

    render() {

        const wine = this.props.wine

        const style = { cursor: fp.isUndefined(this.props.onWineSelected) ? 'normal' : 'pointer' }

        return (

            <div className='wine-wrapper' onClick={this.onClick} style={style}>
                {wine.label && (
                    <div className='wine-prop label'>{wine.label}</div>
                )}
                <div className='wine-prop'>{wine.name}</div>
                {wine.subName && (
                    <div className='wine-prop'> - </div>
                )}
                <div className='wine-prop'>{wine.subName}</div>
                {/* <div className='wine-prop'> - </div> */}
                {/* <div className='wine-prop type'>{fp.capitalize(wine.type)}</div> */}
                {wine.labelImage && (
                    <img key='label' alt='label' {...{
                        src: wine.labelImage,
                        height: 25
                    }} />
                )}
            </div>
        )
    }
}

export default WineRenderer