import fp from 'lodash/fp'
import propTypes from 'prop-types'
import React, { Component } from 'react'
import RegionPropTypes from '../prop-types/region-prop-types'
import MenuServices from '../services'
import VigneronRenderer from './vigneron-renderer';
import WinesListRenderer from './wines-list-renderer';

import './region-page.css';

const fpMap = fp.map.convert({ cap: false })

class RegionPage extends Component {

	static displayName = 'RegionPage'

	static propTypes = {
		goToPage: propTypes.func,
		data: propTypes.shape(RegionPropTypes)
	}

	constructor(props) {

		super(props)

		this.state = {
			region: '',
			vignerons: [],
			wines: []
		}
	}

	componentDidMount() {
		// to be removed if after selecting a new region there is no update
		this.getRegion(this.props.data.uid)
	}

	shouldComponentUpdate(nextProps, nextState) {

		return !fp.isEqual(nextProps.data, this.props.data)
			|| !fp.isEqual(nextState.vignerons, this.state.vignerons)
			|| !fp.isEqual(nextState.wines, this.state.wines)
	}

	// to be removed if after selecting a new region there is no update
	componentWillReceiveProps(nextProps) {
		if (this.state.region !== nextProps.data.uid) {
			this.getRegion(nextProps.data.uid)
		}
	}

	getRegion = region => {

		this.setState({
			region: region,
			vignerons: [],
			wines: []
		})

		MenuServices.getRegions().then(({ regions }) => {

			fp.forEach(({ uid, vigneronsUID }) => {

				if (uid === region) {

					fp.forEach(vigneron => {

						MenuServices.getVigneron(vigneron).then(resVigneron => {

							this.setState({
								vignerons: fp.concat(this.state.vignerons, resVigneron),
								wines: fp.concat(this.state.wines, resVigneron.wines)
							})

						})
					}, vigneronsUID)

					return
				}
			}, regions)
		})
	}

	render() {

		const { props, state } = this

		// to be re-introduced if after selecting a new region there is no update
		// if (this.state.region !== nextProps.data.uid) {
		// 	this.getRegion(nextProps.data.uid)
		// }

		return (

			<div className='region' >

				<div className='vignerons-wrapper'>

					{fpMap((vigneron, index) => {

						return <VigneronRenderer key={index} goToPage={props.goToPage} vigneron={vigneron} />

					}, state.vignerons)}

				</div>

				<div className='wines-wrapper'>

					<WinesListRenderer {...{
						data: state.wines,
						goToPage: props.goToPage
					}} />

				</div>

			</div>
		)
	}
}

export default RegionPage