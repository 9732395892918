import React, { Component } from 'react'
import ContactPage from './components/contact-page'
import HomePage from './components/home-page'
import TopBanner from './components/top-banner';
import RegionPage from './components/region-page'
import VigneronPage from './components/vigneron-page'
import WinesListRenderer from './components/wines-list-renderer';

import './app.css'

class App extends Component {

	static displayName = 'App'

	constructor(props) {

		super(props)

		this.state = {
			page: 'home'
		}
	}

	goToPage = ({ data, page }) => {

		this.setState({
			data,
			page,
		})
	}

	getPageTitle = () => {

		const page = this.state.page

		if (page === 'home') {
			return 'Vins Pop'
		}

		if (page === 'contact') {
			return 'Contact'
		}

		if (page === 'search') {
			return 'Recherche'
		}

		if (page === 'region') {
			return this.state.data.name
		}

		if (page === 'vigneron') {
			return this.state.data.name
		}
	}

	render() {

		const { state } = this

		const pageTitle = this.getPageTitle()

		return (

			<div className='app'>

				<TopBanner {...{
					goToPage: this.goToPage,
					pageTitle
				}} />

				{state.page === 'home' && (
					<HomePage />
				)}

				{state.page === 'region' && (
					<RegionPage {...{
						data: state.data,
						goToPage: this.goToPage
					}} />
				)}

				{state.page === 'vigneron' && (
					<VigneronPage {...{
						data: state.data,
						goToPage: this.goToPage
					}} />
				)}

				{state.page === 'contact' && (
					<ContactPage />
				)}

				{state.page === 'search' && (
					<div className='wine-list-wrapper'>
						<WinesListRenderer {...{
							data: state.data,
							goToPage: this.goToPage
						}} />
					</div>
				)}

				<img key='footer-image' alt='footer' {...{
					className: 'footer-image',
					src: '../assets/footer.jpg',
				}} />

			</div>

		)
	}
}

export default App
