import fp from 'lodash/fp'
import React, { Component } from 'react'
import MenuServices from '../services'

import './home-page.css';

const fpMap = fp.map.convert({ cap: false })

class HomePage extends Component {

	static displayName = 'HomePage'

	constructor(props) {

		super(props)

		this.state = {
			descriptions: [],
		}
	}

	componentDidMount() {

		MenuServices.getHome().then(({ descriptions }) => {

			this.setState({ descriptions })
		})
	}

	render() {

		const { state } = this

		return (
			<div className='home' >

				<div className='home-description-wrapper'>

					{fpMap((description, index) => {

						return <div key={index} {...{
							children: description,
							className: 'home-description'
						}} />
					}, state.descriptions)}

				</div>

			</div>
		)
	}
}

export default HomePage
