import fp from 'lodash/fp'
import React, { Component } from 'react'
import MenuServices from '../services'

import './contact-page.css'

class ContactPage extends Component {

    static displayName = 'ContactPage'

    constructor(props) {

        super(props)

        this.state = {
            email: '',
            firstName: '',
            info: '',
            isSuccess: false,
            lastName: '',
            message: ''
        }
    }

    onChange = ({ target }) => {

        this.setState({ [target.id]: target.value })
    }

    onSubmit = () => {

        const { email, firstName, lastName, message } = this.state

        MenuServices.sendEmail({
            email,
            firstName,
            lastName,
            message,
        }).then(({ error }) => {

            this.setState({
                isSuccess: !error,
                info: error
                    ? 'Ouuups il y a eu un problème, votre message n\'a pas été envoyé'
                    : 'Votre message a bien été envoyé'
            })
        })
    }

    getField = (id, label) => {

        const { state } = this

        return (
            <div className='field'>
                <span>{label}</span>
                <input className='text-box' type='text' id={id} onChange={this.onChange} value={state[id]} />
            </div>
        )
    }

    render() {

        const { state } = this

        const disabled = fp.isEmpty(state.email)
            || fp.isEmpty(state.firstName)
            || fp.isEmpty(state.lastName)
            || fp.isEmpty(state.message)

        return (
            <div className='contact'>

                <div className='contact-wrapper'>

                    <div className='card'>

                        <span className='vins-pop'>Vins Pop</span>
                        <span>Tél: 07 83 62 68 33</span>
                        <span>Email: lesvinspop@gmail.com</span>
                        <span></span>
                        <span>16 avenue Carnot</span>
                        <span>93360 Neuilly-Plaisance</span>
                        <span>France</span>

                    </div>

                    <div className='form' >

                        <div className='contact-message'>
                            Si vous souhaitez un renseignement, n'hésitez pas a nous envoyer un message.
                            Nous vous repondrons dans les plus bref délais.
                        </div>

                        <div className='field-wrapper' >
                            {this.getField('firstName', 'Prénom')}
                            {this.getField('lastName', 'Nom')}
                        </div>

                        {this.getField('email', 'Email')}

                        <div className='field'>
                            <span>Message</span>
                            <textarea {...{
                                className: 'text-box',
                                rows: 10,
                                cols: 50,
                                id: 'message',
                                onChange: this.onChange,
                                value: state.message
                            }} />
                        </div>

                        <button {...{
                            disabled,
                            children: 'Envoyer',
                            className: 'submit',
                            onClick: this.onSubmit
                        }} />

                        <div className={state.isSuccess ? 'success' : 'error'}>
                            {state.info}
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default ContactPage