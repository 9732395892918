import propTypes from 'prop-types'
import WinePropTypes from './wine-prop-types'

export default {
    description: propTypes.arrayOf(propTypes.string),
    image1: propTypes.string,
    image2: propTypes.string,
    name: propTypes.string,
    wines: propTypes.arrayOf(
        propTypes.shape(WinePropTypes)
    )
}
