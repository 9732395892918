import fp from 'lodash/fp'
import propTypes from 'prop-types'
import React, { Component } from 'react'
import VigneronPropTypes from '../prop-types/vigneron-prop-types'
import WinesListRenderer from './wines-list-renderer';

import './vigneron-page.css';

const fpMap = fp.map.convert({ cap: false })

class VigneronPage extends Component {

	static displayName = 'VigneronPage'

	static propTypes = {
		data: propTypes.shape(VigneronPropTypes),
		goToPage: propTypes.func,
	}

	onClickReturn = () => {

		if (this.props.goToPage) {
			this.props.goToPage({
				data: { uid: this.props.data.wines[0].regionUID },
				page: 'region'
			})
		}
	}

	render() {

		const vigneron = this.props.data

		return (
			<div className='vigneron' >

				<div className='vigneron-images'>

					<img alt={`${vigneron.name}1`} {...{
						className: 'vigneron-image',
						src: vigneron.image1
					}} />

					<img alt={`${vigneron.name}2`} {...{
						className: 'vigneron-image',
						src: vigneron.image2
					}} />

				</div>

				<h1 className='vigneron-title' >
					{vigneron.name}
				</h1>

				<h2 className='vigneron-region' onClick={this.onClickReturn}>
					{`Region: ${vigneron.wines[0].region}`}
				</h2>

				<div className='separator' />

				<div className='vigneron-description-wrapper'>

					{fpMap((description, index) => {

						return <div key={index}{...{
							children: description,
							className: 'vigneron-description'
						}} />
					}, vigneron.description)}

				</div>

				<div className='separator' />

				<div className='vigneron-wines'>

					<WinesListRenderer data={vigneron.wines} />

				</div>

			</div>
		)
	}
}

export default VigneronPage