import propTypes from 'prop-types'

export default {
    label: propTypes.string,
    labelImage: propTypes.string,
    name: propTypes.string,
    region: propTypes.string,
    regionUID: propTypes.string,
    subName: propTypes.string,
    type: propTypes.string,
    uid: propTypes.string,
    vigneron: propTypes.string
}
