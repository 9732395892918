import fp from 'lodash/fp'
import propTypes from 'prop-types'
import React, { Component } from 'react'
import Search from '../common-ui/search'
import MenuServices from '../services'

import './search-bar.css';

class SearchBar extends Component {

	static displayName = 'SearchBar'

	static propTypes = {
		goToPage: propTypes.func.isRequired
	}

	constructor(props) {

		super(props)

		this.state = {
			nbOfResult: -1,
			regions: [],
			wines: []
		}
	}

	componentDidMount() {

		MenuServices.getRegions().then(({ keyFields, regions }) => {

			this.setState({ keyFields })

			fp.forEach(({ vigneronsUID }) => {

				fp.forEach(vigneron => {

					MenuServices.getVigneron(vigneron).then(resVigneron => {

						this.setState({
							wines: fp.concat(this.state.wines, resVigneron.wines)
						})

					})
				}, vigneronsUID)

				return
			}, regions)
		})
	}

	onSearch = value => {

		const keyWords = fp.split(' ', value)

		const productsSearched = fp.reduce((acc, wine) => {

			let hasMatch = false

			fp.forEach(keyField => {

				if (!fp.isEmpty(wine[keyField])) {

					const winePropNormalised = wine[keyField].normalize("NFD").replace(/[\u0300-\u036f]/g, "")

					fp.forEach(keyWord => {

						const keyWordNormalised = keyWord.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
						const regexp = new RegExp('\\b' + keyWordNormalised + '\\b', 'i')

						if (regexp.test(winePropNormalised)) {
							hasMatch = true
							return
						}
					}, keyWords)

					if (hasMatch) return
				}

			}, this.state.keyFields)

			return hasMatch === false
				? acc
				: fp.concat(acc, wine)

		}, [], this.state.wines)

		this.setState({
			productsFound: productsSearched,
			nbOfResult: fp.isEmpty(value) ? -1 : fp.size(productsSearched)
		})

		this.props.goToPage({
			data: productsSearched,
			page: 'search'
		})
	}

	goToPage = () => {

		this.props.goToPage({
			data: this.state.productsFound,
			page: 'search'
		})
	}

	render() {

		const { state } = this

		const resultLabel = state.nbOfResult === 0
			? '0 produit trouvé'
			: state.nbOfResult === 1
				? `Voir le produit trouvé`
				: `Voir les ${state.nbOfResult} produits trouvés`

		return (

			<div className='search-bar'>

				<Search {...{
					className: 'search',
					onSubmit: this.onSearch,
					placeholder: 'Recherchez un produit'
				}} />

				{state.nbOfResult !== -1 && (
					<div {...{
						children: resultLabel,
						className: 'nb-of-result',
						onClick: this.goToPage
					}} />
				)}

			</div>
		)
	}
}

export default SearchBar
