import propTypes from 'prop-types'
import React, { Component } from 'react'
import Menu from './menu';
import SearchBar from './search-bar';

import './top-banner.css';

class TopBanner extends Component {

	static displayName = 'TopBanner'

	static propTypes = {
		goToPage: propTypes.func.isRequired,
		pageTitle: propTypes.string
	}

	render() {

		const { props } = this

		return (
			<div className='top-banner' >

				<Menu goToPage={props.goToPage} />

				<img key='main-image' alt='main' {...{
					className: 'main-image',
					src: '../assets/main-bike.png'
				}} />

				<div className='logo'>
					<img key='logo' alt='logo' {...{
						src: '../assets/logo.png',
						height: 100,
						width: 100
					}} />
				</div>

				<div className='search-wrapper'>

					<h1 className='page-title'>
						{props.pageTitle}
					</h1>

					<SearchBar className='search-bar' goToPage={props.goToPage} />

				</div>

			</div>
		)
	}
}

export default TopBanner
