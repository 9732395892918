import axios from 'axios'


const getData = async url => {

    const options = {
        method: 'GET',
        url,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }

    const response = await axios(options)

    const responseOK = response && response.status === 200

    if (responseOK) {
        const data = await response.data

        return data
    } else {
        console.log("error getting menu service")
    }
}

export default {

    getHome: async () => {

        const url = '../json/home.json';

        return getData(url)
    },

    getRegions: async () => {

        const url = '../json/regions.json';

        return getData(url)
    },

    getVigneron: async (vigneron) => {

        const url = `../json/${vigneron}.json`;

        return getData(url)
    },

    sendEmail: async data => {

        const url = '../php/send-email.php';

        const options = {
            method: 'POST',
            url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
                emailFrom: data.email,
                message: data.message,
                subject: `Nouveau message Vins POP de : ${data.firstName} ${data.lastName}`
            }
        }

        try {
            const response = await axios(options)
            const responseOK = response && response.status === 200

            return { error: !responseOK }
        }
        catch (event) {

            return {
                error: true,
                message: event
            }
        }
    }
}
