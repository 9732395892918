import fp from 'lodash/fp'
import propTypes from 'prop-types'
import React, { Component } from 'react'
import Dropdown from '../common-ui/dropdown';
import MenuServices from '../services'

import './menu.css';

const MENU_BUTTONS = [
	{ name: 'Accueil', uid: 'home' },
	{ name: 'Regions', subMenu: true, uid: 'region' },
	{ name: 'Contact', uid: 'contact' }
]

class Menu extends Component {

	static displayName = 'Menu'

	static propTypes = {
		goToPage: propTypes.func.isRequired
	}

	constructor(props) {

		super(props)

		this.state = {
			regions: []
		}
	}

	componentDidMount() {

		MenuServices.getRegions().then(({ regions }) => {

			this.setState({ regions })
		})
	}

	subMenuClicked = ({ target }) => {

		this.props.goToPage({
			data: fp.find({ uid: target.id }, this.state.regions),
			page: 'region'
		})
	}

	menuClicked = ({ target }) => {

		this.props.goToPage({ page: target.id })
	}

	render() {

		const state = this.state

		const MenuButtons = fp.map(btn => {

			return btn.subMenu
				? <Dropdown key={btn.name} {...{
					className: 'menu-button-dropdown',
					items: state.regions,
					itemClassName: 'sub-menu-button',
					itemClicked: this.subMenuClicked,
					label: btn.name
				}} />
				: <div key={btn.name} {...{
					className: 'menu-button',
					children: btn.name,
					id: btn.uid,
					onClick: this.menuClicked
				}} />
		}, MENU_BUTTONS)

		return (

			<div className='menu'>
				{MenuButtons}
			</div>
		)
	}
}

export default Menu
