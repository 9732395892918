import propTypes from 'prop-types'
import React, { Component } from 'react'

const Keys = {
	ENTER: 13
}

class Search extends Component {

	static displayName = 'Search'

    static propTypes = {
		className: propTypes.string,
		onChange: propTypes.func,
		onSubmit: propTypes.func,
		placeholder: propTypes.string,
		value: propTypes.string,
	}

	constructor(props) {

		super(props)

		this.state = {
			value: ''
		}
	}

	onKeyDown = event => {

		if (event.keyCode === Keys.ENTER) {

			this.props.onSubmit(this.state.value)
		}
	}

	onChange = ({ target }) => {

		this.setState({ value: target.value })
	}

	render() {

		const { props, state } = this

		return (

			<input {...{
				className: props.className,
				onChange: this.onChange,
				onKeyDown: this.onKeyDown,
				placeholder: props.placeholder,
				value: state.value
			}} />
		)
	}
}

export default Search