import fp from 'lodash/fp'
import propTypes from 'prop-types'
import React, { Component } from 'react'
import WinePropTypes from '../prop-types/wine-prop-types'
import MenuServices from '../services'
import WineRenderer from './wine-renderer';

import './wines-list-renderer.css';

const fpMap = fp.map.convert({ cap: false })

class WinesListRenderer extends Component {

    static displayName = 'WinesListRenderer'

    static propTypes = {
        data: propTypes.arrayOf(propTypes.shape(WinePropTypes)),
        goToPage: propTypes.func
    }

    onWineSelected = vigneronUID => {

        if (this.props.goToPage) {

            MenuServices.getVigneron(vigneronUID).then(resVigneron => {

                this.props.goToPage({
                    data: resVigneron,
                    page: 'vigneron'
                })
            })
        }
    }

    getWineType = (type, title) => {

        const wineType = fp.filter(wine => wine.type === type, this.props.data)

        const typeNormalized = type.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

        if (!fp.isEmpty(wineType)) {

            return (

                <div>

                    <div className='wine-type'>

                        <img key='vin-rouge' alt='vin-rouge' {...{
                            className: 'wine-type-img',
                            src: `../assets/grape-${typeNormalized}.png`,
                            height: 50
                        }} />

                        <span>{title}</span>

                    </div>

                    <ul className='wine-list'>

                        {fpMap((wine, index) => {

                            return <li key={index}>
                                <WineRenderer {...{
                                    onWineSelected: this.props.goToPage ? this.onWineSelected : undefined,
                                    wine
                                }} />
                            </li>
                        }, wineType)}

                    </ul>

                </div>
            )
        }
    }

    render() {

        return (

            <div className='wine-list-renderer'>

                {this.getWineType('rouge', 'Les Vins Rouges')}
                {this.getWineType('rosé', 'Les Vins Rosés')}
                {this.getWineType('blanc', 'Les Vins Blanc')}
                {this.getWineType('', 'Les Champagnes')}

            </div>
        )
    }
}

export default WinesListRenderer